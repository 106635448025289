h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl {
  margin: 0;
}

.ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  min-height: 44px !important;
}

.ant-select-selection-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #667085 !important;
}

.ant-select-single .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  inset-inline-start: 11px;
  inset-inline-end: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.ant-select-selection-item {
  font-size: 1rem;
  color: #101828;
  margin-top: 5px;
}

.ant-select-clear {
  top: 32% !important;
  right: 10px;
  inset-inline-end: 20px !important;
}

.ant-select-multiple {
  .ant-select-selection-item {
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    background: #fff;
  }
}

.ant-select-selection-item-content {
  font-size: 0.875rem;
  font-weight: 500;
  color: #344054;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:hover {
  border: 1px solid #98a2b3 !important;
  // border-radius: 8px;
  /* Shadow/xs focused 4px primary-100 */
  box-shadow: 0px 0px 0px 4px #eaecf0, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.ant-select-item-option-selected {
  background: #f9fafb;
}

.ant-select-dropdown {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.ant-select-item-option-state {
  color: #667085 !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--gray-50, #f9fafb);
}

.ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-14wwjjs).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: #f0f0f1;
  color: #1d2939;
  border-radius: 8px;
  border: none;
}

.ant-table-wrapper table {
  border-spacing: 5px;
}

.ant-table-row:hover {
  border: 1px solid #eaeaec !important;
  box-shadow: 0px 0px 4px 2px #eaeaec, 0px 2px 4px 0px #eaeaec;
  border-radius: 4px;

  .ant-table-cell {
    background-color: #fff !important;
  }
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none;
  word-wrap: break-word;
  word-break: break-all;
}

.pagination-changer {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-weight: 300;
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #eaecf0;
  border-radius: 20px;
}

.ant-pagination {
  .ant-pagination-item {
    border-radius: 50%;
    background-color: #f0f0f1;
    border: 1px solid #f0f0f1;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    border: 1px solid #98a2b3;
    background-color: #fff;

    a,
    a:hover,
    a:focus {
      color: #344054;
    }
  }
}

.ant-table-pagination.ant-pagination {
  display: flex;
  align-items: center;
}

.ant-input {
  border: 1px solid #d0d5dd;

  &:hover,
  &:focus {
    border: 1px solid #98a2b3;
    box-shadow: 0px 0px 0px 4px #eaecf0, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.ant-btn-default {
  &:hover {
    border: 1px solid #98a2b3;
  }
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #344054;
  border-color: #98a2b3;
}

.ant-switch.ant-switch-checked {
  background-color: #fcd118;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #fcd118;
}

.ant-pagination-total-text {
  font-size: 1rem;
  font-weight: 500;

  position: absolute;
  left: 0;
}

.ant-form-item .ant-form-item-label {
  color: var(--gray-700, #344054);
  font-size: 1rem;
  font-weight: 500;
}

.ant-input-affix-wrapper {
  .ant-input {
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.ant-input-affix-wrapper {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.625rem 0.875rem;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.ant-form-item {
  margin-bottom: 1.25rem;
}

.ant-form-item-control-input-content .ant-input {
  color: var(--gray-500, #667085);
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  padding: 0.5625rem 0.875rem;
}

.table-filter {
  display: flex;
  flex-direction: column;

  .ant-picker {

    margin-bottom: 0.625rem;
  }
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #98a2b3;
    box-shadow: 0 0 0 2px #eaecf0;
    border-inline-end-width: 1px;
    outline: 0;
}

.ant-picker-range .ant-picker-active-bar {
  background: #fcd118;
}

.ant-checkbox .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem;
}

.ant-checkbox-checked .ant-checkbox-inner  {
  background-color: #FFF;
  border-color: #fcd118;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #FEFDF0;
  border-color: #fcd118;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #fcd118;
  background-color: #FEFDF0;
}

.ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid #1D2939;
  border-top: 0;
  border-left: 0;
  display: block;
  width: 7px;
  height: 10px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #fcd118;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #FEFDF0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  border-right: 0;
  top: 40%;
}

.ant-modal {
  .ant-btn-primary, .ant-btn-primary:hover {
    border: 1px solid var(--primary-600, #fcd118);
    background: var(--primary-600, #fcd118) !important;
    color: #344054 !important;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.ant-tooltip {
  z-index: 999999999 !important;
}

.ant-picker {
  height: 44px;
}

.ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #151515;
  font-weight: 600;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #fcd118;
}

.ant-tabs .ant-tabs-tab {
  font-size: 1rem;

  &:hover {
    color: #151515;
  }
}

.ant-input-number {
  color: var(--gray-500, #667085);
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  padding: 0.375rem 0;
  border: 1px solid #d0d5dd;
}

.ant-form-item-control-input-content .ant-input-number {
  width: 100%;
}

.ant-input-number:focus, .ant-input-number:hover, .ant-input-number:focus-within {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
