.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.setup-guide {
  ul {
    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    line-height: 24px;
    background-color: #ecebff;
    border-left: 2px solid #8080ff;
    border-radius: 8px;
    padding: 5px;

    svg {
      width: 75px;
      margin-right: 5px;
    }
  }
}

.sync-data {
  &__schedule-date {
    display: flex;
    gap: 15px;
    align-items: flex-end;

    .ant-form-item {
      width: 33%;
    }
  }
}
